@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    min-height: 100vh;
    /*background: #faf8fc; !* bg-slate-50 *!*/
}

::selection {
    background-color: #294cff!important;
    color: #fff;
}

@layer utilities {
    .text-gradient {
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

/**
* Scroll mouse icon
* https://codepen.io/2xsamurai/pen/WwmjKQ
*/

.mousey {
    width: 4px;
    padding: 8px 10px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
}

.scroller {
    width: 3px;
    height: 8px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
}

@keyframes scroll {
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(8px); opacity: 0;}
}